import React from 'react';
import advocateProfilePic from '../../../assets/images/advocates-details/divyaRani.jpg';

const AdvocateDivyaRaniDetails = () => {
  return (
    <section className='team-details'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-5'>
            <div className='team-details__content'>
              <h2 className='team-details__title'>Read my story</h2>
              <p className='team-details__text'>
                Mrs. R. Divya Rani completed her LLB from Pendekanti law College
                affiliated to Osmania University, Hyderabad. She also did her
                LLM from PG College of Law, Osmania University. She enrolled as
                an advocate in the year 2020. She is specialised in the areas of
                Civil and Criminal laws. She is currently practising at the
                Hon’ble High Court of Telangana, City Civil Courts, Criminal
                Court, and Family Courts.
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='team-one__single'>
              <div className='team-one__image'>
                <img src={advocateProfilePic} alt='' />
              </div>
              <div className='team-one__content'>
                <h2 className='team-one__name'>Mrs. R. Divya Rani, LLM</h2>
                <p className='team-one__designation'>Advocate</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvocateDivyaRaniDetails;
