import AdvocateDivyaRaniDetails from '../../components/about/advocates-details/divyaRani';
import Footer from '../../components/common/footer';
import Layout from '../../components/common/layout';
import NavigationMenu from '../../components/common/navigationMenu';
import PageHeader from '../../components/common/pageHeader';
import React from 'react';

const AdvocateDivyaRaniDetailsPage = () => {
  return (
    <Layout pageTitle='Avadhani & Associates | Divya Rani'>
      <NavigationMenu active='about' />
      <PageHeader
        title='Divya Rani'
        subMenu='Advocates'
        backLink='/about#team-section'
      />
      <AdvocateDivyaRaniDetails />
      <Footer />
    </Layout>
  );
};

export default AdvocateDivyaRaniDetailsPage;
